import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import React, { component } from 'react';
import config from './config/config';
import Home from './Components/Home'


function App() {
  return (
    <BrowserRouter>

      <Switch>
        <Route path={`${config.baseUrl}`} exact component={Home} />
      </Switch>

    </BrowserRouter>
  );
}

export default App;
