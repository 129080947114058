import React, { useEffect, useState } from 'react';
import config from '../config/config'
import { BrowserView, MobileView } from 'react-device-detect';
import Web3 from 'web3';
import Swal from 'sweetalert2'
import { Dialog, Classes } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import BarLoader from 'react-bar-loader'
import Onboard from 'bnc-onboard'
import Fortmatic from 'fortmatic';
import getWhiteListUsers from '../config/getWhiteListUsers';


let web3;

const onboard = Onboard({
  dappId: "a830de3a-cbe0-4fc2-9242-5c9c406f6fb2",
  networkId: 0x1,
  subscriptions: {
    wallet: wallet => {
      web3 = new Web3(wallet.provider)
    }
  },

  walletSelect: {
    wallets: [
      {
        walletName: 'metamask'
      },
      {
        walletName: 'coinbase'
      },
    ]
  }

});

const Home = (props) => {

  const fm = new Fortmatic('pk_test_644846FACB88893F', "ropsten");
  const web3 = new Web3(fm.getProvider());

  const [isConnectMetamask, setisConnectMetamask] = useState(false);
  const [ConnectWalletAddress, setConnectWalletAddress] = useState('');
  const [MintText, setMintText] = useState('Mint');
  const [TotalMined, setTotalMined] = useState(0);
  const [mintLimit, setMintLimit] = useState(888);
  const [purchased_quantity, setpurchased_quantity] = useState(1);
  const [errorAvailable, seterrorAvailable] = useState('');
  const [isDialogOpen, setisDialogOpen] = useState(false);
  const [iscoinbaseconnect, setiscoinbaseconnect] = useState(false);
  const [ismetamaskconnect, setismetamaskconnect] = useState(false);


  const [isConnectCoinbase, setisConnectCoinbase] = useState(false);


  useEffect(() => {
    // if (typeof window.ethereum !== 'undefined') {
    //   console.log('MetaMask is installed!');
    // }
    // connectMetaMask()
    setTimeout(() => {
      if (window.ethereum && localStorage.getItem('isLogout')) {
        const { ethereum } = window;
        if (ethereum.selectedAddress) {
          var web3 = new Web3(window.ethereum);
          var currentNetwork = web3.currentProvider.chainId;
          // if (currentNetwork != '56' && currentNetwork != '0x61') {
          //     Cookies.set('loginSuccessMetaseer', "");
          //     return;
          // }
          setConnectWalletAddress(ethereum.selectedAddress)
        }
      }
    }, 1000);
  }, [])

  const accountLogout = async () => {
    localStorage.setItem('walletType', '');
    localStorage.setItem('connectWalletAddress', '');
    localStorage.setItem('isLogout', true);
    window.location.reload();
  }



  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  //  walletConnectFun
  const setDefault = async () => {
    setismetamaskconnect(false)
    // setismetamaskconnect1(false)

    setiscoinbaseconnect(false)
    // setiscoinbaseconnect1(false)

  }

  const cwalletClick = async () => {
    setismetamaskconnect(false)
    // setismetamaskconnect1(false)

    setiscoinbaseconnect(false)
  }

  async function openMetamask() {


    await onboard.walletSelect();
    connectMetaMask()
    connectMetaMask1()
  }

  const connectMetaMask = async () => {
    if (window.ethereum) {
      var isMetaMask = await window.ethereum.isMetaMask;
      if (!isMetaMask) {
        setismetamaskconnect(true)
        // toast.error(`Please install Metamask wallet to use this App!`);
        return;
      }

      if (!window.ethereum.providers) {
        var metamaskProvider = await window.ethereum;
      } else {
        var metamaskProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
      }
      try {
        const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
        setConnectWalletAddress(accounts[0])
        setisConnectMetamask(true)
        localStorage.setItem('walletType', 'metamask')
        localStorage.setItem('connectWalletAddress', accounts[0]);
        localStorage.setItem('isLogout', false);
        window.location.reload();
      } catch (err) {

        Swal.fire({
          text: err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Close',
          confirmButtonColor: '#ae7d5c',
          background: '#311a12',
          color: '#fff'
        })
      }

    }
    else {
      setismetamaskconnect(true)
      // toast.error(`Please install MetaMask to use this App!`, {

      // });
    }
  }

  const connectMetaMask1 = async () => {
    if (window.ethereum) {

      if (!window.ethereum.providers) {
        if (window.ethereum.isCoinbaseWallet == true) {
          var coinbaseProvider = await window.ethereum;
        } else {
          // alert('44422223333')

          setiscoinbaseconnect(true)
          // toast.error(`Please install Coinbase wallet to use this App!`);
          return;
        }
      } else {

        var coinbaseProvider = await window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      }

      try {

        const accounts = await coinbaseProvider.request({ method: 'eth_requestAccounts' });
        setConnectWalletAddress(accounts[0])
        localStorage.setItem('walletType', 'coinbase')
        localStorage.setItem('connectWalletAddress', accounts[0])
        localStorage.setItem('isLogout', false);
        window.location.reload();
        setisConnectCoinbase(true);
      } catch (err) {
        Swal.fire({
          text: err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Close',
          confirmButtonColor: '#ae7d5c',
          background: '#311a12',
          color: '#fff'
        })
      }

    }
    else {
      // alert('4442222wqewq')

      setiscoinbaseconnect(true)
      // toast.error(`Please install Coinbase to use this App!`, {

      // });
    }
  }

  async function manageWallet(address) {
    let cc = await localStorage.getItem("connectWalletAddress").toString();
    let add = await address.toString();

    if (cc.toUpperCase() != add.toUpperCase()) {
      localStorage.setItem("connectWalletAddress", address);
      window.location.reload();
    }
  }


  const minusQuantity = () => {
    var qty = parseInt(purchased_quantity) - parseInt(1)
    if (purchased_quantity > 1) {
      setpurchased_quantity(qty)
      // this.setState({
      //    'purchased_quantity': qty
      // })
    }

    // if (qty > responsedata.available_quantity) {
    //    seterrorAvailable(1)


    // }
    else if (qty === '0' || qty === '') {
      seterrorAvailable(2)
    }
    else {
      seterrorAvailable(0)
    }
  }


  const plusQuantity = () => {
    var qty = parseInt(purchased_quantity) + parseInt(1);
    if (qty > 10) {
      return;
    }
    setpurchased_quantity(qty)


    if (qty === '0' || qty === '') {
      seterrorAvailable('2')
    }
    else {
      seterrorAvailable('0')
    }

  }

  const getCurrentProvider = async () => {
    let CurrentProvider = "";
    if (localStorage.getItem("connectWalletAddress") && localStorage.getItem("walletType") == 'metamask') {
      if (!window.ethereum.providers) {
        CurrentProvider = await window.ethereum;
      } else {
        CurrentProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
      }
    } else {
      if (!window.ethereum.providers) {
        if (window.ethereum.isCoinbaseWallet == true) {
          CurrentProvider = await window.ethereum;
        }
      } else {
        CurrentProvider = await window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      }
    }
    return CurrentProvider;
  }


  const mintItem = async (e) => {

    if (MintText === "Processing...") {
      return;
    }

    let nftNumber = parseInt(purchased_quantity);
    if (nftNumber > 10) {
      Swal.fire({
        text: '10 NFTs mint in per transaction',
        // icon: 'warning',
        title: "Alert",
        confirmButtonText: 'Close',
        confirmButtonColor: 'rgb(1 2 2)',
        background: 'rgb(141 96 153)',
        color: '#fff'
      })
      return;
    }
    // if (parseInt(TotalMined) + nftNumber > parseInt(mintLimit)) {
    //   let mint = parseInt(mintLimit) - parseInt(TotalMined);
    //   Swal.fire({
    //     text: 'Only ' + mint + ' slot left for mint',
    //     // icon: 'warning',
    //     title: "Alert",
    //     confirmButtonText: 'Close',
    //     confirmButtonColor: 'rgb(1 2 2)',
    //     background: 'rgb(141 96 153)',
    //     color: '#fff'
    //   })
    //   return;
    // }
    if (!localStorage.getItem("connectWalletAddress")) {
      connectMetaMask();
      return;
    }
    const providers = await getCurrentProvider();
    const web3 = new Web3(providers);

    try {
      web3.eth.defaultAccount = localStorage.getItem("connectWalletAddress");


      if (web3.currentProvider.networkVersion != 1 && web3.currentProvider.networkVersion != '0x1') {

        Swal.fire({
          text: 'Please select Ethereum network!',
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Close',
          confirmButtonColor: 'rgb(1 2 2)',
          background: 'rgb(141 96 153)',
          color: '#fff'
        })
        return;
      }
      setMintText("Processing...");
      setisDialogOpen(true);
      const NFT_CONTRACT = await new web3.eth.Contract(config.ABI, config.contractAddress);
      let from_address = localStorage.getItem("connectWalletAddress");
      // let balance = await web3.eth.getBalance(from_address);

      let chainId = '0x1';


      let getMintFees = await NFT_CONTRACT.methods.getMintFees(nftNumber).call();
      getMintFees = parseInt(getMintFees);

      let myAddress = from_address.toUpperCase();

      let contractOnwer = await NFT_CONTRACT.methods.owner().call();

      let encoded_tx = "";
      if (myAddress == contractOnwer.toUpperCase()) {
        let tx_builder = await NFT_CONTRACT.methods.mintAsOwner(nftNumber);
        encoded_tx = tx_builder.encodeABI();
        getMintFees = 0;
      } else {
        let tx_builder = await NFT_CONTRACT.methods.mint(nftNumber);
        encoded_tx = tx_builder.encodeABI();


        // let whitelist = await getWhiteListUsers.address;
        // if (!whitelist.includes(from_address.toUpperCase())) {

        //   await Swal.fire({
        //     text: 'Presale minting is allowed for whitelisted users only. Public sale will start on 15th April, (10:30 AM EST)',
        //     // icon: 'warning',
        //     title: "Notice",
        //     confirmButtonText: 'Close',
        //     confirmButtonColor: 'rgb(1 2 2)',
        //     background: 'rgb(141 96 153)',
        //     color: '#fff'
        //   })

        //   setisDialogOpen(false);
        //   setMintText("Mint");
        //   return;
        // }
      }



      let gasPrice = await web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice) + 10000000000;

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: config.contractAddress,
        from: from_address,
        value: web3.utils.toHex(getMintFees),
        chainId: chainId,
        data: encoded_tx
      });

      const transactionParameters = {
        from: from_address,
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: config.contractAddress,
        value: web3.utils.toHex(getMintFees),
        chainId: chainId,
        data: encoded_tx
      };

      const txHash = await web3.eth.sendTransaction(transactionParameters);
      // let messageAlert = `<span>Transaction details : <a style="color:blue" href="https://etherscan.io/tx/${txHash.transactionHash}" target="_blank">0x....hash</a> </span>`;
      // messageAlert = ` ${messageAlert} <br/> <a style="color:blue" href="https://testnets.opensea.io/${localStorage.getItem("connectWalletAddress")}" target="_blank">View on Opensea</a> </span>`

      if (txHash.transactionHash) {
        Swal.fire({
          width: 500,
          title: 'Successful!',
          html: 'Congratulation! NFT minted Successfully.',
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Ok, Thanks',
          confirmButtonColor: 'rgb(1 2 2)',
          background: 'rgb(141 96 153)',
          color: '#fff'
        }).then((result) => {
          window.location.reload();
        })
      }
    } catch (err) {
      console.log("err", err)
      setMintText("Mint");
      setisDialogOpen(false);
      if (err.message.toString().split('insufficient funds')[1]) {

        Swal.fire({
          text: 'Transaction reverted : ' + err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Close',
          confirmButtonColor: 'rgb(1 2 2)',
          background: 'rgb(141 96 153)',
          color: '#fff'
        })
      } else {
        if (err.toString().split('execution reverted:')[1]) {
          Swal.fire({
            text: 'Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0],
            // icon: 'warning',
            title: "Alert",
            confirmButtonText: 'Close',
            confirmButtonColor: 'rgb(1 2 2)',
            background: 'rgb(141 96 153)',
            color: '#fff'
          })
        } else {
          if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
            Swal.fire({
              text: "Transaction reverted : insufficient funds for transaction fee",
              // icon: 'warning',
              title: "Alert",
              confirmButtonText: 'Close',
              confirmButtonColor: 'rgb(1 2 2)',
              background: 'rgb(141 96 153)',
              color: '#fff'
            })
          } else {
            Swal.fire({
              text: err.message,
              // icon: 'warning',
              title: "Alert",
              confirmButtonText: 'Close',
              confirmButtonColor: 'rgb(1 2 2)',
              background: 'rgb(141 96 153)',
              color: '#fff'
            })
          }
        }
      }
    }

  }

  const connectWallet = async () => {


    try {

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x1',
                chainName: 'Ethereum Mainnet',
                rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
              },
            ],
          });
        } catch (addError) {
          console.log("addError", addError)
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }


  return (
    <>
      <section className="banner">
        <Dialog
          title="Processing..."
          // icon="warning-sign"
          style={{
            color: 'red',
          }}
          isOpen={isDialogOpen}
          isCloseButtonShown={false}
        >
          <div className="text-center">
            <BarLoader color="#e84747" height="2" />
            <br />
            <h4 style={{ color: '#000' }}>Transaction under process...</h4>
            <p style={{ color: 'red' }}>
              Please do not refresh page or close tab.
            </p>
            <div>
            </div>
          </div>
        </Dialog>

        <div className="bg_black">
          <div className="container-fluid">
            <div className="row mint_row align-items-center">
              {/* <img src="./img/banner.webp"> */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="mint_steps">
                  <h2>
                    MINT A NFT  <br />
                  </h2>
                  <h3>
                    <span>Step 1:</span>
                  </h3>
                  <p>Connect Wallet</p>
                  <h3>
                    <span>Step 2:</span>
                  </h3>
                  <p>Mint now</p>
                  {/* <p>
                    <span>10 NFTs per wallet</span>
                  </p> */}

                  <p style={{ paddingTop: '20px' }}>Smart Contract<br /><a href='https://etherscan.io/address/0x468a1baad52e170c14db1e0d141429c56102cdf3' target="_blank"><span>(0x468a...02cdf3) <i className='fas fa-external-link-alt'></i> </span></a></p>


                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">

                <div className="mint_box">
                  {/* {ConnectWalletAddress ?
                    <a  href={`https://etherscan.io/address/${ConnectWalletAddress}`} target="_blank" className="btn btnSuccess" style={{ minWidth: '40%' }} onClick={connectMetaMask}>
                      Wallet...{ConnectWalletAddress.slice(-5)}
                    </a>
                    :
                    <a className="btn btnSuccess" style={{ minWidth: '40%' }} onClick={connectMetaMask}>
                      Connect Wallet
                    </a>
                  } */}



                  {!localStorage.getItem('connectWalletAddress') ?
                    <>
                      <BrowserView>
                        <a style={{ minWidth: '40%' }} id={!localStorage.getItem('connectWalletAddress') ? '' : 'connecting_wallet'} className="btn btnSuccess" data-toggle="modal" data-target="#connect_wallet_modal" onClick={cwalletClick}>Connect Wallet</a>
                      </BrowserView>

                      <MobileView>
                        <a style={{ minWidth: '40%' }} id={!localStorage.getItem('connectWalletAddress') ? '' : 'connecting_wallet'} className="btn btnSuccess" data-toggle="collapse" data-target="#collapsingNavbar" aria-controls="collapsingNavbar" aria-expanded="false" aria-label="Toggle navigation" type='submit' onClick={openMetamask}>Connect Wallet</a>
                      </MobileView></>

                    :
                    <>
                      <a style={{ minWidth: '50%' }} className="btn btnSuccess" title={localStorage.getItem('connectWalletAddress')}  >{localStorage.getItem('connectWalletAddress').toString().substring(0, 5) + '...' + localStorage.getItem('connectWalletAddress').toString().substr(localStorage.getItem('connectWalletAddress').length - 5)}  |  </a>
                      <a style={{ 'left': '270px', border: 'unset', cursor: 'pointer' }} title='Disconnect Wallet' className="btn btnSuccess" onClick={e => accountLogout(e)}><i className='fas fa-sign-out-alt'></i></a>
                    </>
                  }



                  <div className="numbers">
                    <h1>{purchased_quantity}</h1>
                    <img src="./img/minus.png" className="sign" onClick={e => minusQuantity(e)} />
                    <img src="./img/number_wap.png" className="num" />
                    <img src="./img/plus.png" className="sign" onClick={e => plusQuantity(e)} />
                  </div>
                  <div className="box">


                    <a className="button" style={{ width: '32%' }} onClick={mintItem}>
                      {MintText}
                    </a>

                  </div>
                  <div id="popup1" className="overlay">
                    <div className="popup">
                      <a className="close" href="#">
                        ×
                      </a>
                      <div className="content">
                        <h4>Install or enable metamask</h4>
                        <h4>
                          To interact with website you must install or enable
                          metamask
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="connect_wallet_modal" tabindex="-1" role="dialog" aria-labelledby="connect_walletModal3Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModal3Label"><b>Connect Wallet</b></h5>
              <button type="button" className="close closeModal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="connect_wallet_panel">

                {ismetamaskconnect === false && iscoinbaseconnect === false ?
                  <div class="row">
                    <div className='col-sm-6 col-6'>
                      <div className="connect_wallet_div" onClick={e => connectMetaMask(e)}>
                        <img src="assets/img/metamask.svg" alt="Icon" />
                        <p style={{ color: '#333' }} className="mb-0"  >Metamask Wallet</p>
                      </div>

                    </div>
                    <div className='col-sm-6 col-6'>
                      <div className="connect_wallet_div" onClick={e => connectMetaMask1(e)}>
                        <img src="assets/img/coinbas.webp" alt="Icon" />
                        <p style={{ color: '#333' }} className="mb-0"  >Coinbase Wallet</p>
                      </div>

                    </div>

                  </div> :
                  ismetamaskconnect === true ?
                    <div class="row">

                      <div className='col-sm-12 col-12'>
                        <img src="assets/img/metamask.svg" alt="Icon" style={{ height: '40px', width: '40px' }} />&nbsp;&nbsp;
                        <span style={{ color: '#fff' }} className="mb-0">MetaMask Wallet</span>
                        <br />
                        <p style={{ color: '#fff' }} className="mb-0"> You'll need to install MetaMask to continue. Once you have it installed, go ahead. </p>

                      </div>

                      <div className='col-sm-6 col-6' style={{ marginTop: '5px' }}>
                        <a href='javascript:void(0)' type="submit" onClick={setDefault}>Back</a>
                      </div>

                      <div className='col-sm-6 col-6'>
                        <a href="https://metamask.io/download" target="_blank" className="coinbase-web">Open Metamask Wallet</a>

                      </div>
                    </div> :

                    iscoinbaseconnect === true ?


                      <div class="row">

                        <div className='col-sm-12 col-12'>
                          <img src="assets/img/coinbas.webp" style={{ height: '40px', width: '40px' }} alt="Icon" />&nbsp;&nbsp;
                          <span style={{ color: '#fff' }} className="mb-0">Coinbase Wallet</span>
                          <br />
                          <p style={{ color: '#fff' }} className="mb-0"> You will need to install Coinbase Wallet to continue. Click below to install. </p>

                        </div>

                        <div className='col-sm-6 col-6' style={{ marginTop: '5px' }}>
                          <a href='javascript:void(0)' type="submit" onClick={setDefault}>Back</a>
                        </div>

                        <div className='col-sm-6 col-6'>
                          <a href="https://www.coinbase.com/wallet" target="_blank" className="coinbase-web">Open Coinbase Wallet</a>

                        </div>
                      </div> :
                      ''}


              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default Home;